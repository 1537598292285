/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.
 
 */
import React, { useCallback, useState } from 'react';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';
import {
  Grid,
  Stack,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  CircularProgress,
  Button,
} from '@mui/material';
import PropTypes from 'prop-types';
import {
  StyledToolbarGrid,
  StyledSearchIcon,
  StyledClearIcon,
  StyledDataGrid,
  StyledFileUploadButton,
  StyledNavigateButton,
  ScrollableTableBox,
} from './datagrid.styled';
import { useTranslation } from 'react-i18next';
import { PaginationTextTypography } from '../../pages/settings/individualFileCollectionPage/individualFileCollection.styled';
import {
  Add,
  Clear,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Search,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import DropdownOne from '../dropdownone';
import { RowPerPageOptions } from '../../constants/rowPerPageOptions';

const DataGridTable = ({
  row,
  rowId,
  column,
  pageSize,
  handleRow,
  rowHeightAuto,
  sortingOrder,
  sortingField,
  isAddButtonNeeded,
  path,
  serverSideSearch,
  isSearching,
  onSearch,
  buttonText,
  isManagePropertiesNeeded = false,
  buttonTextForManageProperties = '',
  handleNavigate,
  customPageNo,
  customPageSize,
  total,
  onCustomPageChange,
  onCustomPageSizeChange,
  isNextPageLoading,
  isPaginationRequired = false,
  disableSelectionOnClick = true,
  showSelectedCount = false,
  showTotalCount = true,
  autoHeight = true,
  totalDisplayText = 'Total',
  onAddButtonClick,
  withoutHeader = false,
  searchPlaceholder,
  ...props
}) => {
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState('');

  const handleSearch = (e) => {
    onSearch(e?.target?.value || '');
    setSearchText(e?.target?.value || '');
  };

  const CustomSearchField = () => (
    <TextField
      fullWidth
      value={searchText}
      onChange={handleSearch}
      variant="standard"
      placeholder={searchPlaceholder ? searchPlaceholder : 'Search...'}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        endAdornment: searchText && (
          <InputAdornment position="end">
            {isSearching ? (
              <CircularProgress size={18} />
            ) : (
              <IconButton onClick={handleSearch}>
                <Clear />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
  );

  const CustomToolbar = () => {
    return (
      <StyledToolbarGrid container>
        <Grid item xs={6} sm={6} md={7}>
          {serverSideSearch ? CustomSearchField() : <GridToolbarQuickFilter />}
        </Grid>

        <Grid display="flex" alignItems={'center'} gap={1}>
          {showSelectedCount && (
            <Typography
              sx={{ marginRight: 2 }}
              variant="h5"
              fontWeight="bold"
              gutterBottom
            >
              {`Selected: ${props?.selectionModel?.length} / ${
                total || row.length
              }`}
            </Typography>
          )}
          {showTotalCount && (
            <Typography
              sx={{ marginRight: 2 }}
              variant="h5"
              fontWeight="bold"
              gutterBottom
            >
              {`${totalDisplayText} : ${row.length}${
                (row.length === customPageSize || total > row.length) && !total
                  ? '+'
                  : ''
              }${total ? `/ ${total}` : ''}`}
            </Typography>
          )}

          {isAddButtonNeeded && (
            <Grid item xs={12} sm={3} md={'auto'}>
              <StyledFileUploadButton
                variant="contained"
                size="medium"
                component={onAddButtonClick ? Button : Link}
                to={path}
                onClick={onAddButtonClick}
                startIcon={<Add />}
              >
                {buttonText}
              </StyledFileUploadButton>
            </Grid>
          )}

          {isManagePropertiesNeeded && (
            <Grid item xs={12} sm={5} md={'auto'}>
              <StyledNavigateButton
                variant="contained"
                size="medium"
                onClick={handleNavigate}
              >
                {buttonTextForManageProperties}
              </StyledNavigateButton>
            </Grid>
          )}
        </Grid>
      </StyledToolbarGrid>
    );
  };

  const CustomPagination = () => (
    <Stack direction="row" spacing={3} alignItems="center">
      <Grid container alignItems="center" spacing={1} sx={{ pb: 1 }}>
        <Grid item>
          <Typography variant="body1">
            {' '}
            {t(`fileCollectionsPage.dataGrid.columns.rows`)}
          </Typography>
        </Grid>
        <Grid item alignItems="center">
          <DropdownOne
            value={customPageSize}
            onChange={(value) => onCustomPageSizeChange(value)}
            label=""
            items={RowPerPageOptions}
          />
        </Grid>
      </Grid>

      <Stack direction="row" alignItems="center">
        <IconButton
          onClick={() => onCustomPageChange(customPageNo - 1)}
          disabled={customPageNo === 1}
        >
          <KeyboardArrowLeft />
        </IconButton>

        {total ? (
          <Stack
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            flexDirection="row"
          >
            <PaginationTextTypography>
              {(customPageNo - 1) * customPageSize + 1}
            </PaginationTextTypography>
            <PaginationTextTypography> - </PaginationTextTypography>
            <PaginationTextTypography>
              {Math.min(customPageNo * customPageSize, total)}
            </PaginationTextTypography>
            <PaginationTextTypography>
              {t(`fileCollectionsPage.dataGrid.columns.of`)}
            </PaginationTextTypography>
            <PaginationTextTypography>{total}</PaginationTextTypography>
          </Stack>
        ) : (
          <PaginationTextTypography>{customPageNo}</PaginationTextTypography>
        )}

        <IconButton
          onClick={() => onCustomPageChange(customPageNo + 1)}
          disabled={
            total
              ? customPageNo * customPageSize >= total
              : row.length < customPageSize
          }
        >
          <KeyboardArrowRight />
        </IconButton>
      </Stack>
    </Stack>
  );

  const components = {
    ...(!withoutHeader &&
      !serverSideSearch && {
        Toolbar: CustomToolbar,
        QuickFilterIcon: StyledSearchIcon,
        QuickFilterClearIcon: StyledClearIcon,
      }),
    ...(isPaginationRequired && { Pagination: CustomPagination }),
  };

  return (
    <>
      {serverSideSearch && CustomToolbar()}
      <ScrollableTableBox>
        <StyledDataGrid
          {...props}
          getRowId={(row) => row[rowId]}
          autoHeight={autoHeight}
          page={customPageNo}
          pageSize={customPageSize || pageSize}
          initialState={{
            sorting: {
              sortModel: [{ field: sortingField, sort: sortingOrder }],
            },
          }}
          loading={isNextPageLoading}
          rows={row}
          paginationMode={isPaginationRequired ? 'server' : 'client'}
          columns={column}
          disableColumnMenu
          disableSelectionOnClick={disableSelectionOnClick}
          onRowClick={handleRow}
          components={components}
          getRowHeight={() => (rowHeightAuto ? 'auto' : null)}
        />
      </ScrollableTableBox>
    </>
  );
};
DataGridTable.propTypes = {
  row: PropTypes.array,
  rowId: PropTypes.string,
  column: PropTypes.array,
  pageSize: PropTypes.number,
  handleRow: PropTypes.func,
  rowHeightAuto: PropTypes.bool,
  sortingOrder: PropTypes.string,
  sortingField: PropTypes.string,
  isAddButtonNeeded: PropTypes.bool,
  path: PropTypes.any,
  serverSideSearch: PropTypes.bool,
  isSearching: PropTypes.bool,
  onSearch: PropTypes.func,
  buttonText: PropTypes.string,
  isManagePropertiesNeeded: PropTypes.bool,
  buttonTextForManageProperties: PropTypes.string,
  handleNavigate: PropTypes.func,
  customPageNo: PropTypes.string,
  customPageSize: PropTypes.string,
  total: PropTypes.string,
  onCustomPageChange: PropTypes.func,
  onCustomPageSizeChange: PropTypes.func,
  isPaginationRequired: PropTypes.bool,
  isNextPageLoading: PropTypes.bool,
  disableSelectionOnClick: PropTypes.bool,
  showSelectedCount: PropTypes.bool,
  totalDisplayText: PropTypes.string,
  onAddButtonClick: PropTypes.func,
  selectionModel: PropTypes.array,
  isRowSelectable: PropTypes.func,
  withoutHeader: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  showTotalCount: PropTypes.bool,
  autoHeight: PropTypes.bool,
};

export default DataGridTable;
