/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.
 
 */
import { Grid, Icon, Button, styled, Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Search, Clear } from '@mui/icons-material';

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 'none',
  '& .MuiDataGrid-columnHeaders': {
    color: theme.palette.secondary.dark,
    fontSize: 16,
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus':
    {
      outline: 'none',
    },
  '&.MuiDataGrid-root .MuiDataGrid-columnSeparator': {
    visibility: 'hidden',
  },
  '&.MuiDataGrid-root .MuiDataGrid-iconButtonContainer:focus': {
    outline: 'none',
  },
  '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer': {
    visibility: 'visible',
  },
  '&.MuiDataGrid-toolbarContainer': {
    color: theme.palette.primary.main,
  },
  '.MuiDataGrid-row': {
    color: theme.palette.secondary.dark,
    cursor: 'pointer',
  },
  '&.MuiDataGrid-root .MuiDataGrid-footerContainer': {
    display: 'flex',
    justifyContent: 'center',
  },
  '&.MuiDataGrid-root .MuiDataGrid-virtualScroller': {
    overflow: 'auto',
    scrollbarWidth: 'none',
  },
}));

export const StyledToolbarGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    width: '100%',
    paddingTop: theme.spacing(1),
  },
}));

export const StyledFileUploadIcon = styled(Icon)(({ theme }) => ({
  height: 20,
  marginRight: theme.spacing(0.5),
}));

export const StyledFileUploadButton = styled(Button)(({ theme }) => ({
  float: 'right',
  [theme.breakpoints.up('xs')]: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
    marginTop: theme.spacing(0),
  },
}));

export const StyledSearchIcon = styled(Search)(({ theme }) => ({
  color: theme.palette.primary.main,
  padding: theme.spacing(0),
}));

export const StyledClearIcon = styled(Clear)(({ theme }) => ({
  color: theme.palette.primary.main,
  padding: theme.spacing(0),
}));

export const StyledNavigateButton = styled(Button)(({ theme }) => ({
  float: 'right',
  [theme.breakpoints.up('xs')]: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
    marginTop: theme.spacing(0),
  },
}));

export const ScrollableTableBox = styled(Box)(({ theme }) => ({
  overflow: 'scroll',
  scrollbarWidth: 'none',
  height: '85%',
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(0),
    height: '90%',
  },
}));
