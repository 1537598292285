/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { Box, Paper, Typography, styled } from '@mui/material';
import { Link } from 'react-router-dom';

const StyledFooterPaper = styled(Paper)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  borderRadius: 0,
  padding: theme.spacing(0, 2),
  zIndex: theme.zIndex.drawer + 1,
  borderTop: `2px solid #CCCCCC`,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textAlign: 'center',
  alignContent: 'center',
  marginTop: theme.spacing(0.5),
  marginRight: theme.spacing(1),
}));

const StyledNavigationSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: 'initial',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
}));

const StyledLeftSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-evenly',
  typography: 'caption',
  '& > :not(style) + :not(style)': {
    marginLeft: theme.spacing(2),
  },
  padding: theme.spacing(1, 0),
}));

const LogoBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    marginRight: 0,
  },
}));

const LogoWrap = styled(Box)(({ theme }) => ({
  color: '#EF4F26',
  marginTop: theme.spacing(1),
}));

const FooterLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
  fontSize: 12,
}));

export {
  StyledFooterPaper,
  StyledNavigationSection,
  StyledTypography,
  StyledLeftSection,
  FooterLink,
  LogoBox,
  LogoWrap,
};
