/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import {
  Icon,
  OutlinedInput,
  styled,
  Box,
  IconButton,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import { FilterList, Search } from '@mui/icons-material';
import colors from '../../themes/colors';

export const SearchInput = styled(OutlinedInput)(({ theme }) => ({
  borderRadius: theme.spacing(0),
  padding: '12px 13px 12px 16px',
  backgroundColor: '#F3F3F3',
  '.MuiInputBase-input': {
    fontSize: 30,
  },
  [theme.breakpoints.down('sm')]: {
    // height: '70px',
    '.MuiInputBase-input': {
      fontSize: 18,
      padding: 0,
      lineHeight: 1.5,
      maxHeight: '7.5em',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 5,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      wordWrap: 'break-word',
    },
  },
}));

export const SearchBoxContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: 0,
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const AdornmentBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const SearchButton = styled(IconButton)(({ theme }) => ({
  borderRadius: theme.spacing(0),
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  padding: theme.spacing(1, 3),
  backgroundColor: theme.palette.primary.main,
  marginRight: -13,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

export const LanguageFormControl = styled(FormControl)(({ theme }) => ({
  borderRadius: 0,
  width: 130,
  p: 0,
  '& .MuiFormLabel-root': {
    paddingTop: '14px',
    fontWeight: 600,
  },
  '& .MuiFilledInput-root': {
    //remove default hover underline
    '&:before': {
      borderBottom: '0',
    },
    '&:after': {
      borderBottom: 'none',
    },
    '&:hover': {
      '&:before': {
        borderBottom: '0 !important',
      },
    },
  },
}));

export const SelectLanguage = styled(Select)(({ theme }) => ({
  padding: 0,
  borderRadius: 0,
  height: '78px',
  '.MuiSelect-select': {
    color: '#131E29',
    paddingTop: '0',
    paddingBottom: '0px',
    fontSize: '14px',
    fontWeight: '300',
  },
  ':hover': {
    border: '0 !important',
  },
}));

export const AdornmentSearchIcon = styled(Icon)(({ theme }) => ({
  width: 36,
  height: 62,
  [theme.breakpoints.down('sm')]: {
    width: 26,
  },
}));

export const SearchMobileContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export const FilterIcon = styled(FilterList)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const StyledSearchIcon = styled(Search)(({ theme }) => ({
  color: theme.palette.common.white,
}));

export const LanguageContainer = styled(Box)(({ theme }) => ({
  display: 'block',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const Inputlabel = styled(InputLabel)(({ theme }) => ({
  color: 'black',
  paddingTop: 0,
  fontWeight: 'bold',
  fontSize: 16,
}));

export const FilterButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));
