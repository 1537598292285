/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterList, KeyboardArrowDown, Search } from '@mui/icons-material';
import 'language-flags/index.css';
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useAuth } from 'react-oidc-context';
import isSearchFilterEnabled from '../../constants/searchFilter';
import {
  SearchButton,
  AdornmentSearchIcon,
  SearchInput,
  SearchBoxContainer,
  SelectLanguage,
  LanguageFormControl,
  AdornmentBox,
  SearchMobileContainer,
  FilterIcon,
  StyledSearchIcon,
  LanguageContainer,
  Inputlabel,
  FilterButton,
} from './searchbar.styled';
import './searchbar-custom-styles.css';
import { ReactComponent as SearchIcon } from '../../assets/search-icon.svg';
import { useLazyGetLanguagesQuery } from '../../redux/services/speciphicAsk';

const SearchbarBase = ({
  onSearchClick,
  onFilterClick,
  value,
  onChange,
  placeholder,
  setQueryLanguage,
  langFromUrl,
  isMetadataSearch = false,
  showFilter = true,
}) => {
  const auth = useAuth();
  const [language, setLanguage] = useState('');
  let [sortLanguages, setSortLanguages] = useState('');
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [currentLanguage, setCurrentLanguage] = useState();
  const { t } = useTranslation();

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (value.length !== 0 || isMetadataSearch) {
      onSearchClick(value);
    }
  };

  const handleSearchChange = (e) => {
    e.preventDefault();
    onChange(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleFormSubmit(e);
    }
  };

  const handleChangeLanguage = (e) => {
    setLanguage(e.target.value);
    setCurrentLanguage(
      sortLanguages.filter((lang) => lang.value === e.target.value)[0],
    );
  };

  const [
    getLanguages,
    {
      data: languages = [],
      isError: isGetlanguagesError,
      isLoading: isGetlanguagesLoading,
      isSuccess: isGetlanguagesSuccess,
    },
  ] = useLazyGetLanguagesQuery();

  useEffect(() => {
    if (auth?.isAuthenticated) getLanguages();
  }, [auth?.isAuthenticated]);

  useEffect(() => {
    currentLanguage && setQueryLanguage(currentLanguage);
  }, [currentLanguage]);

  useEffect(() => {
    let languagesTemp = [...languages];
    let sortLanguages = languagesTemp.sort((a, b) =>
      a.value.localeCompare(b.value),
    );

    if (langFromUrl !== undefined) {
      const matchedLanguage = sortLanguages.find(
        (lang) => lang.code === langFromUrl,
      );

      if (matchedLanguage) {
        sortLanguages = [
          matchedLanguage,
          ...sortLanguages.filter((lang) => lang.code !== langFromUrl),
        ];
      }
    }

    setSortLanguages(sortLanguages);
    languages.length && setLanguage(sortLanguages[0]?.value);
    setCurrentLanguage(sortLanguages[0]);
  }, [languages, langFromUrl]);

  return (
    <>
      <FormControl
        fullWidth
        sx={{
          display: { xs: 'block', md: 'none' },
          mb: 2,
          width: { xs: '100%', sm: '100%' },
        }}
        size="small"
      >
        <InputLabel size="small">
          {t('speciphicAskPage.searchbarBase.label')}
        </InputLabel>

        <Select
          value={language}
          disabled={language.length == 0}
          label="{t('speciphicAskPage.searchbarBase.label')}"
          onChange={handleChangeLanguage}
          sx={{ width: '100%' }}
          size="small"
          IconComponent={(props) => <KeyboardArrowDown {...props} />}
        >
          {sortLanguages.length &&
            sortLanguages.map((lang) => {
              return (
                <MenuItem key={lang.id} value={lang.value}>
                  {lang.value}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>

      <SearchBoxContainer component="form" onSubmit={handleFormSubmit}>
        <SearchInput
          multiline={smallScreen ? true : false}
          fullWidth
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          autoFocus
          sx={{ border: 'none', '& fieldset': { border: 'none' } }}
          value={value}
          onChange={handleSearchChange}
          endAdornment={
            <AdornmentBox>
              <InputAdornment position="end">
                {showFilter && (
                  <FilterButton onClick={onFilterClick}>
                    <FilterList />
                  </FilterButton>
                )}

                <LanguageContainer>
                  <LanguageFormControl variant="filled" size="small">
                    <Inputlabel size="small">
                      {t('speciphicAskPage.searchbarBase.label')}
                    </Inputlabel>

                    <SelectLanguage
                      size="small"
                      value={language}
                      label={t('speciphicAskPage.searchbarBase.label')}
                      onChange={handleChangeLanguage}
                      IconComponent={(props) => (
                        <KeyboardArrowDown {...props} />
                      )}
                    >
                      {sortLanguages.length &&
                        sortLanguages.map((lang) => {
                          return (
                            <MenuItem key={lang.id} value={lang.value}>
                              <Grid container spacing={2}>
                                <Grid item container xs={6}>
                                  <Typography
                                    variant="body1"
                                    className="lang-name"
                                    marginTop={2}
                                  >
                                    {lang.value}
                                  </Typography>
                                </Grid>
                                <Grid item container xs={6}>
                                  <Typography
                                    marginTop={2.3}
                                    variant="body1"
                                    className={
                                      'lf ' +
                                      lang.value.toLowerCase() +
                                      ' lang-icon'
                                    }
                                  ></Typography>
                                </Grid>
                              </Grid>
                            </MenuItem>
                          );
                        })}
                    </SelectLanguage>
                  </LanguageFormControl>
                </LanguageContainer>
                <SearchButton
                  aria-label="search"
                  onClick={handleFormSubmit}
                  edge="end"
                  color="primary"
                >
                  <AdornmentSearchIcon component={SearchIcon} />
                </SearchButton>
              </InputAdornment>
            </AdornmentBox>
          }
        />
        {smallScreen && (
          <SearchMobileContainer>
            {isSearchFilterEnabled && (
              <Button
                variant="outlined"
                onClick={onFilterClick}
                startIcon={<FilterIcon />}
              >
                {t('speciphicAskPage.searchbarBase.button.filter')}
              </Button>
            )}

            <Button
              variant="contained"
              startIcon={<StyledSearchIcon />}
              onClick={handleFormSubmit}
            >
              {t('speciphicAskPage.searchbarBase.button.search')}
            </Button>
          </SearchMobileContainer>
        )}
      </SearchBoxContainer>
    </>
  );
};

SearchbarBase.propTypes = {
  onSearchClick: PropTypes.func,
  onFilterClick: PropTypes.func,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  setQueryLanguage: PropTypes.func,
  langFromUrl: PropTypes.string,
  isMetadataSearch: PropTypes.bool,
  showFilter: PropTypes.bool,
};

export default SearchbarBase;
