const { REACT_APP_MAX_UPLOAD_SIZE } = process.env;
export const ALLOWED_FILE_TYPES = {
  'application/vnd.ms-powerpoint': ['.ppt'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': [
    '.pptx',
  ],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
    '.docx',
  ],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
  ],
  'image/jpeg': ['.jpeg', '.jpg'],
  'image/png': ['.png'],
  'image/gif': ['.gif'],
  'text/plain': ['.txt', '.text'],
  'text/html': ['.htm', '.html'],
  'application/pdf': ['.pdf'],
};
export const ALLOWED_FILE_SIZE = REACT_APP_MAX_UPLOAD_SIZE;
