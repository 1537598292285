/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { Grid, Link, Tooltip, CircularProgress, Box } from '@mui/material';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ErrorOutline } from '@mui/icons-material';

import {
  ContentBox,
  StyledPdfBox,
  StyledPdfContiner,
  AnsWrapper,
  PdfTypography,
  ViewerContainerWrapper,
  DocViewerContainer,
  PreviewCenterDiv,
  IframeStyled,
  ViewerCover,
  DocPreview,
  DataGridStyled,
} from './answer.styled';
import PdfPreview from '../../pages/searchPage/pdfPreview';
import DialogBox from '../../pages/searchPage/pdfDialogBox';
import MarkdownComponent from '../markdown.component';
import AnswerFooter from './answer.footer';
import { StyledCircularIcon } from '../../pages/searchPage/pdfPreview/pdfPreview.styled';
import { getFileExtension, getPDFDataURI } from '../../utils/functions';
import { extensionRegEx } from '../../utils/regex';
import { PREVIEW_EXTENSIONS } from '../../constants/queryAnswer';
import isSignedUrlSupportEnabled from '../../constants/signedUrlConfiguration';
import {
  viewerSupportExtensions,
  docViewerUrlPrefix,
  iframeSupportExtensions,
} from '../../constants/docViewerConfig';
import { RowPerPageOptions } from '../../constants/rowPerPageOptions';
import {
  useLazyDownloadDBTableResultQuery,
  useLazyPaginateDBTableResultQuery,
} from '../../redux/services/speciphicAsk';

/**
 * AnswerWithPdf Component
 * Renders a component that displays a title, description, and PDF previews.
 *
 * @param {Object} props - The component props.
 * @param {string} props.title - The title to be displayed.
 * @param {string} props.description - The description to be displayed.
 * @param {Array} props.metas - Metadata for the PDF files.
 * @param {boolean} props.pdfPreview - Whether to show PDF previews.
 * @param {Function} props.feedback - Feedback callback function.
 * @param {Object} props.feedbackAPI - API object for feedback.
 * @param {Object} props.result - The result object.
 * @param {string} props.query - The query string.
 * @param {boolean} props.isDefaultFeedbackRequired - Whether default feedback is required.
 *
 * @returns {JSX.Element} The rendered component.
 */
const AnswerWithPdf = ({
  title,
  description,
  metas,
  pdfPreview,
  feedback,
  feedbackAPI,
  result,
  query,
  collectionId,
  isDefaultFeedbackRequired,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [meta, setMeta] = useState(null);
  const [fileQuery, setFileQuery] = useState(
    metas.map((m, i) => {
      return {
        loading: true,
        error: false,
      };
    }),
  );

  const DEFAULT_TABLE_SIZE = RowPerPageOptions[2].value;

  const [preparedMetas, prepareMetas] = useState([]);
  const [metasWithDataUri, setMetasWithDataUri] = useState([]);

  const [paginatedResultLoading, setPaginatedResultLoading] = useState(false);
  const [tablePageNumber, setTablePageNumber] = useState(1);
  const [tablePageSize, setTablePageSize] = useState(DEFAULT_TABLE_SIZE);
  const [tableResults, setTableResults] = useState({
    rows: [],
    columns: [],
  });
  const [downloadingTableResults, setDownloadingTableResults] = useState(false);

  const [getPaginatedTableResult] = useLazyPaginateDBTableResultQuery();
  const [downloadTableResult] = useLazyDownloadDBTableResultQuery();

  useEffect(() => {
    if (result?.tableResults?.length > 0) {
      const row1 = result.tableResults[0];

      const columns = [];

      for (const field in row1) {
        columns.push({
          field,
          headerName: field,
          minWidth: 200,
          flex: 1,
        });
      }

      setTableResults({
        columns,
        rows: result.tableResults.slice(0, DEFAULT_TABLE_SIZE).map((r, i) => {
          return {
            id: i + 1,
            ...r,
          };
        }),
      });
    }
  }, [result]);

  useEffect(() => {
    const tempMetas = [];

    for (let i in metas) {
      const meta = metas[i];
      let tempMeta = { ...meta };

      if (meta?.signed_url) {
        const fileExtension = getFileExtension(meta.signed_url);

        if (viewerSupportExtensions.includes(fileExtension))
          tempMeta.preview_url = `${docViewerUrlPrefix}${encodeURIComponent(
            meta.signed_url,
          )}`;
        else if (iframeSupportExtensions.includes(fileExtension))
          tempMeta.preview_url = meta.signed_url;
      }

      tempMetas.push(tempMeta);
    }

    prepareMetas(tempMetas);
  }, [metas]);

  const fetchAndAppendDataURIs = async (metas) => {
    const dataURIs = {}; // key - url, value - dataURI

    metas.forEach((m) => {
      if (m?.dataURI) return;
      dataURIs[m.url] = '';
    });

    await Promise.all(
      Object.keys(dataURIs).map(async (url) => {
        try {
          const res = await getPDFDataURI(url);
          dataURIs[url] = res;
        } catch (e) {
          dataURIs[url] = 'ERROR';
        }

        const updatedMetas = metas.map((m) => ({
          ...m,
          dataURI: dataURIs[m.url] || m.dataURI,
        }));

        setMetasWithDataUri(updatedMetas);
      }),
    );
  };

  useEffect(() => {
    if (metas && metas.length > 0) {
      const tempMetas = [];

      // filter only pdfs
      metas.forEach((m) => {
        if (PREVIEW_EXTENSIONS.includes(extensionRegEx.exec(m?.fileName)[1]))
          tempMetas.push(m);
      });

      setMetasWithDataUri(tempMetas);
      if (!isSignedUrlSupportEnabled) fetchAndAppendDataURIs(tempMetas);
    }
  }, [metas]);

  const handleDialogClickOpen = (meta) => {
    setMeta(meta);
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleUpdateRows = (dbResults) => {
    if (dbResults?.tableResults?.length > 0)
      setTableResults({
        ...tableResults,
        rows: dbResults.tableResults.map((r, i) => {
          return {
            ...r,
            id: i,
          };
        }),
      });
  };

  const handleTablePageChange = async (newPageNumber) => {
    setTablePageNumber(newPageNumber);

    try {
      setPaginatedResultLoading(true);
      const dbResults = await getPaginatedTableResult({
        collectionId,
        chatId: result.id,
        pageNo: newPageNumber,
        pageSize: tablePageSize,
      }).unwrap();
      handleUpdateRows(dbResults);
    } catch (ex) {
      console.error(ex);
    } finally {
      setPaginatedResultLoading(false);
    }
  };

  const handleTablePageSize = async (newValue) => {
    setTablePageSize(newValue);

    try {
      setPaginatedResultLoading(true);
      const dbResults = await getPaginatedTableResult({
        collectionId,
        chatId: result.id,
        pageNo: tablePageNumber,
        pageSize: newValue,
      }).unwrap();
      handleUpdateRows(dbResults);
    } catch (ex) {
      console.error(ex);
    } finally {
      setPaginatedResultLoading(false);
    }
  };

  const showTable = () => {
    return tableResults.rows.length > 0;
  };

  const handleClickDownloadTableResults = async () => {
    setDownloadingTableResults(true);
    await downloadTableResult({ collectionId, chatId: result.id });
    setDownloadingTableResults(false);
  };

  return (
    <>
      <AnsWrapper>
        <ContentBox>
          <MarkdownComponent markdown={title} />
        </ContentBox>
        <ContentBox>
          <MarkdownComponent markdown={description} />

          {showTable() && (
            <DataGridStyled
              customPageSize={tablePageSize}
              customPageNo={tablePageNumber}
              row={tableResults.rows}
              column={tableResults.columns}
              rowId={'id'}
              isPaginationRequired
              isNextPageLoading={paginatedResultLoading}
              withoutHeader
              total={result?.totalRows}
              onCustomPageChange={handleTablePageChange}
              onCustomPageSizeChange={handleTablePageSize}
            />
          )}

          {pdfPreview &&
            (isSignedUrlSupportEnabled ? (
              <ViewerContainerWrapper>
                {preparedMetas
                  .filter((pm) => pm.preview_url)
                  .map((m, i) => (
                    <DocViewerContainer key={i} onClick={() => setOpen(m)}>
                      <DocPreview>
                        {fileQuery[i].loading && (
                          <PreviewCenterDiv>
                            <CircularProgress />
                          </PreviewCenterDiv>
                        )}

                        {fileQuery[i].error && (
                          <PreviewCenterDiv>
                            <ErrorOutline />
                          </PreviewCenterDiv>
                        )}

                        <IframeStyled
                          onLoad={() => {
                            const tempFileQuery = [...fileQuery];
                            tempFileQuery[i].loading = false;
                            setFileQuery(tempFileQuery);
                          }}
                          onError={() => {
                            const tempFileQuery = [...fileQuery];
                            tempFileQuery[i].error = true;
                            setFileQuery(tempFileQuery);
                          }}
                          src={`${m?.preview_url}#page=${m?.page}&toolbar=0&navpanes=0&scrollbar=0`}
                        />

                        <ViewerCover />

                        <Box>{m?.fileName}</Box>
                      </DocPreview>
                    </DocViewerContainer>
                  ))}
              </ViewerContainerWrapper>
            ) : metasWithDataUri?.length > 0 ? (
              <>
                <Grid container>
                  {metasWithDataUri.map((metaWithDataUri, index) => (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      justifyContent={'center'}
                      display={'flex'}
                    >
                      <StyledPdfContiner>
                        <StyledPdfBox
                          onClick={() => handleDialogClickOpen(metaWithDataUri)}
                        >
                          {metaWithDataUri?.dataURI ? (
                            <PdfPreview
                              pageNumber={metaWithDataUri.page}
                              scale={0.3}
                              dataUri={metaWithDataUri?.dataURI}
                              isLoading={!metaWithDataUri?.dataURI}
                            />
                          ) : (
                            <StyledCircularIcon />
                          )}
                        </StyledPdfBox>

                        <Link underline="none" color="inherit">
                          <Tooltip title={metaWithDataUri.fileName}>
                            <PdfTypography>
                              {metaWithDataUri.fileName}
                            </PdfTypography>
                          </Tooltip>
                        </Link>
                      </StyledPdfContiner>
                    </Grid>
                  ))}
                </Grid>
              </>
            ) : (
              <></>
            ))}

          {isDefaultFeedbackRequired && (
            <AnswerFooter
              title={title}
              description={description}
              meta={meta}
              feedback={feedback}
              feedbackAPI={feedbackAPI}
              query={query}
              result={result}
              showDownloadButton={showTable()}
              downloadButtonOnClick={handleClickDownloadTableResults}
              isDownloading={downloadingTableResults}
            />
          )}
        </ContentBox>
      </AnsWrapper>

      {isSignedUrlSupportEnabled ? (
        <DialogBox
          open={open ? true : false}
          file={open}
          handleClose={() => setOpen(false)}
        />
      ) : meta && meta.dataURI !== 'ERROR' ? (
        <DialogBox
          open={open}
          dataUri={meta.dataURI}
          file={meta}
          handleClose={handleDialogClose}
          pageNumber={meta.page}
        />
      ) : (
        <></>
      )}
    </>
  );
};

AnswerWithPdf.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  metas: PropTypes.array,
  fileName: PropTypes.string,
  pdfPreview: PropTypes.bool,
  feedback: PropTypes.func,
  feedbackAPI: PropTypes.object,
  result: PropTypes.object,
  query: PropTypes.string,
  isDefaultFeedbackRequired: PropTypes.bool,
  collectionId: PropTypes.string,
};

export default AnswerWithPdf;
